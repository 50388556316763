import axios from 'axios';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export const signIn = async (loginData) => {
  try {
    // Send request

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(loginData);

    const res = await axios.post(
      clientConfig.backendHOST + '/auth/Login',
      body,
      config
    );

    if (res.status === 200) {
      return {
        isOk: true,
        data: res.data.returnData,
      };
    }
  } catch (err) {
    return {
      isOk: false,
      message: 'Sikertelen bejelentkezés',
    };
  }
};

export async function getUser() {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if (localStorage.token) {
    let res;
    try {
      res = await axios.get(clientConfig.backendHOST + '/auth');
    } catch (e) {
      console.error(e);
    }

    if (res) {
      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data.returnData,
        };
      }
      if (res.status === 403) {
        localStorage.removeItem('token');
        return {
          isOk: false,
        };
      } else {
        return {
          isOk: false,
        };
      }
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(userData) {
  try {
    // Send request

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(userData);

    const res = await axios.post(
      clientConfig.backendHOST + '/auth/User',
      body,
      config
    );

    if (res.status === 200) {
      return {
        isOk: true,
        message: 'Fiók sikeresen létrehozva',
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function orderBook(userData) {
  try {
    // Send request

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(userData);

    const res = await axios.post(
      clientConfig.backendHOST + '/auth/BookOrder',
      body,
      config
    );

    if (res.status === 200) {
      return {
        isOk: true,
        message: 'Sikeres megrendelés',
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: 'Hiba a megrendelés során',
    };
  }
}

export async function changePassword(password, recoveryCode) {
  try {
    // Send request
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      newPW: password,
      recoveryCode: recoveryCode,
    });

    const res = await axios.post(
      clientConfig.backendHOST + '/auth/changePW',
      body,
      config
    );

    if (res.status === 200) {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
      };
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ userMail: email });

    const res = await axios.post(
      clientConfig.backendHOST + '/auth/resetPW',
      body,
      config
    );
    if (res.status === 200) {
      return {
        isOk: true,
      };
    } else {
      return {
        isOk: false,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}
