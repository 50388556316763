import React, { createContext, useContext, useEffect, useState } from 'react';
import { siteTracer } from '../api/site';

const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props) {
  const [navigationData, setNavigationData] = useState({
    currentPath: '',
  });

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component, path) {
  const WrappedComponent = function (props) {
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      siteTracer({ action_type: 'NAVIGATION', route_visit: path });
      if (path !== '/order') {
        localStorage.removeItem('orderItem');
      }

      setNavigationData({ currentPath: path });
    }, [path, setNavigationData]);

    return <Component {...props} />;
  };
  return <WrappedComponent />;
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
