import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider, useAuth } from './contexts/auth';
import { MenuContextProvider } from './contexts/menuContextProvider';
import { NavigationProvider } from './contexts/navigation';
import './dx-styles.scss';
import './global.scss';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import { useScreenSizeClass } from './utils/media-query';
import setAuthToken from './utils/setAuthToken';

import loadable from '@loadable/component';
import CookieConsent from './contexts/CookieConsent';

const Content = loadable(() => import('./Content'));
const UnauthenticatedContent = loadable(() =>
  import('./UnauthenticatedContent')
);

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
  }, []);

  return (
    <Router>
      <CookieConsent />
      <AuthProvider>
        <MenuContextProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </MenuContextProvider>
      </AuthProvider>
    </Router>
  );
}
