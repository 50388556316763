import React, { createContext, useCallback, useContext, useState } from 'react';
import { siteTracer } from '../api/site';

function MenuContextProvider(props) {
  const [menuContext, setMenuContext] = useState();

  const setMenu = useCallback((menuContext) => {
    setMenuContext(menuContext);
    siteTracer({
      action_type: 'NAVIGATION',
      route_visit: `/videos/${menuContext.title}`,
    });

    return menuContext;
  }, []);

  const unloadMenu = useCallback(() => {
    setMenuContext(undefined);
  }, []);

  return (
    <MenuContext.Provider
      value={{ menuContext, setMenu, unloadMenu }}
      {...props}
    />
  );
}

const MenuContext = createContext();
const useMenuContext = () => useContext(MenuContext);

export { MenuContextProvider, useMenuContext };
