import axios from 'axios';
import clientConfig from '../client-config';
import setAuthToken from '../utils/setAuthToken';

export const reportError = async (message) => {
  try {
    // Send request
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    if (localStorage.token) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post(
        clientConfig.backendHOST + '/site/siteMessage',
        message,
        config
      );

      if (res.status === 200) {
        return {
          isOk: true,
          data: res.data.returnData,
        };
      }
    }
  } catch (err) {
    return {
      isOk: false,
      message: 'Sikeres hibabejelentés',
    };
  }
};

export const siteTracer = async (message) => {
  try {
    // Send request
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(
      clientConfig.backendHOST + '/site/logAction',
      message,
      config
    );

    return {
      isOk: true,
    };
  } catch (err) {
    console.log(err);
    return {
      isOk: false,
    };
  }
};
