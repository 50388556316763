import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { siteTracer } from '../api/site';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      console.log('reload user');

      const result = await getUser();

      if (result.isOk) {
        setUser(result.data);
      } else {
        siteTracer({ action_type: 'NAVIGATION', route_visit: '/main' });
      }

      setLoading(false);
    })();
  }, [loading]);

  const ReloadUserRights = () => {
    setLoading(true);
  };
  const signIn = useCallback(async (loginData) => {
    const result = await sendSignInRequest(loginData);

    if (result.isOk) {
      setUser(result.data);
      localStorage.setItem('token', result.data.token);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    localStorage.removeItem('token');
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, ReloadUserRights, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
