import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Button from 'devextreme-react/button';
import './CookieConsent.scss';

function CookieConsent() {
  const [cookies, setCookie] = useCookies(['cookie-consent']);
  const [showBanner, setShowBanner] = useState(!cookies['cookie-consent']);

  const handleAccept = () => {
    setCookie('cookie-consent', true, { path: '/', maxAge: 9600 }); // 604800
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className='cookie-consent'>
          <p>
            A weboldalunk sütiket (cookie-kat) használ, hogy biztonságos
            böngészés mellett a legjobb felhasználói élményt nyújtsa. Az oldal
            használatával elfogadom és tudomásul veszem a cookiek használatát
            valamint a mindenkor érvényes{' '}
            <a href={process.env.PUBLIC_URL + '/aszf'} target='_blank'>
              ÁSZF
            </a>
            -et és{' '}
            <a href={process.env.PUBLIC_URL + '/gdpr'} target='_blank'>
              Adatvédelmi Tájékoztatót
            </a>
            .
          </p>
          <Button
            text='Tudomásul vettem és elfogadom'
            type='success'
            onClick={handleAccept}
          ></Button>
        </div>
      )}
    </>
  );
}

export default CookieConsent;
